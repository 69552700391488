<template>
  <div>
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      :immediate-check="false"
      @load="loadMoreFc"
    >
      <div class="wrp">
        <div class="list">
          <template
            class="list-item"
            v-for="(item, index) in leftData"
            :key="index"
          >
            <item-card :item="item" />
          </template>
        </div>

        <div class="list">
          <template
            class="list-item"
            v-for="(item, index) in rightData"
            :key="index"
          >
            <item-card :item="item" />
          </template>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { List as VanList } from "vant";
import ItemCard from "./item-card.vue";

import { getQuery } from "@/utils/url";
import NftApi from "@/apis";

defineProps({
  currentTab: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["onClickItem"]);

const listData = ref([]);
const leftData = ref([]);
const rightData = ref([]);
const loading = ref(false);
const finished = ref(false);
const leftHeight = ref(0);
const rightHeight = ref(0);
const currentItemHeight = ref(0);

const loadMoreFc = (aa) => {
  initData();
};

const initData = async () => {
  const { subjectId } = getQuery();

  loading.value = true;

  const { data } = await NftApi.getSubjectList({
    subjectId,
    limit: 10,
    offset: listData.value.length,
  });

  loading.value = false;
  // const dpr = window.devicePixelRatio

  if (!data?.list?.length) {
    finished.value = true;
    return;
  }

  const itemWidth = 348;
  const defaultWidth = 348;
  const defaultHeight = 464;
  const windowWidth = document.body.clientWidth;
  const scaleRatio = windowWidth / 750;
  // detailData = reactive(data.token || {})
  const tempData = data.list.map((item) => {
    let tempImgInfo = {
      url: item.imageUrl,
    };

    if (item?.covers?.[0]?.url) {
      tempImgInfo = item.covers[0];
    } else if (item?.watermaskImages?.[0]?.url) {
      tempImgInfo = item.watermaskImages[0];
    }

    // 如果不是竖图 是长图
    if (+tempImgInfo.width > +tempImgInfo.height) {
      tempImgInfo.calcWidth = scaleRatio * 344;
      tempImgInfo.calcHeight = scaleRatio * 344;
    } else {
      if (tempImgInfo.width) {
        const imgRatio = itemWidth / tempImgInfo.width;
        tempImgInfo.calcWidth = scaleRatio * itemWidth;
        tempImgInfo.calcHeight = scaleRatio * (imgRatio * tempImgInfo.height);
      } else {
        tempImgInfo.calcWidth = scaleRatio * defaultWidth;
        tempImgInfo.calcHeight = scaleRatio * defaultHeight;
      }
    }

    item.coverImgInfo = tempImgInfo;
    return item;
  });

  let data1 = [];
  let data2 = [];
  // let leftHeight = 0;
  // let rightHeight = 0;
  // let currentItemHeight = 0;

  if (tempData && tempData.length > 0) {
    tempData.map((item, index) => {
      if (
        leftHeight.value == rightHeight.value ||
        leftHeight.value < rightHeight.value
      ) {
        leftHeight.value +=
          item.coverImgInfo.calcHeight || currentItemHeight.value;
        data1.push({
          renderIndex: index + 1,
          ...item,
        });
      } else {
        rightHeight.value +=
          item.coverImgInfo.calcHeight || currentItemHeight.value;
        data2.push({
          renderIndex: index + 1,
          ...item,
        });
      }

      if (item.coverImgInfo.calcHeight > 0) {
        currentItemHeight.value = item.coverImgInfo.calcHeight;
      }
    });
  }

  leftData.value = leftData.value.concat(data1);
  rightData.value = rightData.value.concat(data2);
  listData.value = listData.value.concat(tempData);

  const total = data.total;
  const hasMore = listData.value.length < total;
  if (hasMore) {
    finished.value = false;
  } else {
    finished.value = true;
  }
};

initData();
</script>

<style lang="scss" scoped>
.wrp {
  margin-top: 30rpx;
  padding: 0 20rpx;
  display: flex;
  flex-direction: row;
}
.list {
  width: 100%;
  //column-count: 4;
  //column-gap: 0;
  display: flex;
  flex-direction: column;
  //flex-flow: column wrap;
  //align-content: center;
  //flex-wrap: wrap;
  //justify-content: space-between;
  //background-color: skyblue;
  &-item {
    //flex: 0 0 348rpx;
    //flex: 0 0 200rpx;
    //background-color: skyblue;
    //border: 1rpx solid red;
    &--img {
      //width: 174rpx;
      //height: auto;
    }
  }
  &-item:nth-child(2n) {
    margin-right: 0;
    //background-color: red;
  }
  &-item--active {
    position: relative;
    font-weight: bold;
    font-size: 48rpx;
    line-height: 64rpx;
    color: #000000;
    .bg-line {
      position: absolute;
      width: 80%;
      height: 24rpx;
      background: linear-gradient(
        90.09deg,
        rgba(114, 71, 220, 0.5) -4.2%,
        rgba(114, 71, 220, 0) 104.04%
      );
      transform: translateX(15%) rotate(-20deg);
    }
  }
}
</style>
