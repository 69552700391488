<template>
  <div class="menu">
    <template v-for="(item, index) in items" :key="index">
      <div
        :class="`menu-item ${
          currentTab === item.key ? 'menu-item--active' : ''
        } ${item.extClass}
        `"
        @click="handleClickItem(item)"
      >
        {{ item.name }}
        <div class="bg-line" />
      </div>
    </template>
    <div class="right-content">
      <slot name="rightContent" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const items = [
  { key: "token", name: "作品" },
  {
    key: "auction",
    name: "拍卖",
    isOpenApp: true,
    // extClass: "mobLink::subject::auction",
  },
  {
    key: "product",
    name: "一口价",
    isOpenApp: true,
    // extClass: "mobLink::subject::price",
  },
];

defineProps({
  currentTab: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["onClickItem"]);

const handleClickItem = (item) => {
  emits("onClickItem", item);
};
</script>

<style lang="scss" scoped>
.menu {
  position: relative;
  margin-top: 60rpx;
  padding: 0 30rpx;
  display: flex;
  align-content: center;
  //justify-content: center;
  &-item {
    display: flex;
    align-items: center;
    margin-right: 64rpx;
    font-weight: 400;
    font-size: 40rpx;
    line-height: 52rpx;
    color: rgba(0, 0, 0, 0.3);
  }
  &-item--active {
    position: relative;
    font-weight: bold;
    font-size: 48rpx;
    line-height: 64rpx;
    color: #000000;
    .bg-line {
      position: absolute;
      width: 80%;
      height: 24rpx;
      background: linear-gradient(
        90.09deg,
        rgba(114, 71, 220, 0.5) -4.2%,
        rgba(114, 71, 220, 0) 104.04%
      );
      transform: translateX(15%) rotate(-20deg);
    }
  }
  .right-content {
    position: absolute;
    right: 30rpx;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
