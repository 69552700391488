<template>
  <div class="wrp">
    <div class="bar-wrp">
      <select-bar
        :currentKey="currentKey"
        :menu="menu"
        @onClickItem="onClickItem"
      />
    </div>
    <div class="split-line" />
    <auction-list :sortType="currentKey" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import SelectBar from "@/components/SelectBar/index.vue";
import AuctionList from "./auction-list.vue";

// 1 即将截拍，2最新发布，3最近成交
const menu = [
  { name: "即将截拍", key: 1 },
  { name: "最新发布", key: 2 },
  { name: "最近成交", key: 3 },
];

const currentKey = ref(1);

const onClickItem = (item) => {
  currentKey.value = item.key;
};
</script>

<style lang="scss" scoped>
.wrp {
  margin-top: 40rpx;
  .bar-wrp {
    padding: 0 30rpx;
  }
  .split-line {
    padding: 0 30rpx;
    width: 100%;
    border-bottom: 2rpx solid rgba(32, 36, 38, 0.06);
  }
}
</style>
