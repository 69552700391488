<template>
  <div class="bar">
    <div
      :class="`bar-item ${currentKey === item.key && 'bar-item--active'}`"
      v-for="(item, index) in menu"
      :key="index"
      @click="handleClickItem(item)"
    >
      {{ item.name }}
      <div class="active-bottom-bar" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  menu: {
    type: Array,
    required: true,
  },
  currentKey: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(["onClickItem"]);

const handleClickItem = (item) => {
  emits("onClickItem", item);
};
</script>

<style lang="scss" scoped>
.bar {
  display: flex;
  //align-items: center;
  //justify-content: center;
  &-item {
    margin-right: 40rpx;
    font-size: 28rpx;
    line-height: 44rpx;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
  }
  &-item:nth-child(1) {
    //margin-left: 10rpx;
  }
  &-item--active {
    font-weight: 500;
    color: #000000;
    .active-bottom-bar {
      margin-top: 8rpx;
      width: 100%;
      height: 4rpx;
      background: #000000;
    }
  }
}
</style>
