<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <div :class="['page']">
    <!--    <browser-cover />-->
    <download-banner @onInvokeApp="onOpenSubjectPage" />
    <hover-guide-btn @onInvokeApp="onOpenSubjectPage" />
    <!--    <img-->
    <!--      class="top-cover"-->
    <!--      :src="detailData.cover + '!mfit_w750_h750_jpg'"-->
    <!--      alt=""-->
    <!--    />-->
    <van-image
      fit="cover"
      class="top-cover"
      :src="detailData.cover + '!mfit_w750_h750_jpg'"
      alt=""
    />
    <div class="info">
      <div class="info-title">{{ detailData.name }}</div>
      <div
        class="info-desc"
        v-html="detailData.description"
        style="white-space: pre-line"
      />
    </div>

    <div class="content">
      <menu-bar :currentTab="currentTab" @onClickItem="onClickMenuItem">
        <template v-slot:rightContent>
          <DuButton
            @click="onShowBidList"
            type="recommend"
            size="small"
            extClass="list-btn"
            >交易概况</DuButton
          >
        </template>
      </menu-bar>
      <token-list v-if="currentTab === 'token'" />

      <auction-content v-if="currentTab === 'auction'" />

      <product-list v-if="currentTab === 'product'" />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, shallowRef, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Image as VanImage } from "vant";
import { DuButton } from "@frontend/dumpling";
import NftApi from "@/apis";
import DownloadBanner from "@/components/DownloadBanner";
// import HoverGuideBtn from "@/components/HoverGuideBtn";
import { getQuery } from "@/utils/url";
import MenuBar from "./components/menu-bar";
import TokenList from "./components/token-list";
import ProductList from "./components/product-list";
import AuctionContent from "./components/auction-content";
import { getCountdownTime } from "@/utils/time";
import { invokeAppByYYB } from "@/utils/mob";

const router = useRouter();

let detailData = ref({});
const currentTab = ref("token");
// const currentTab = ref("product");

const onOpenSubjectPage = () => {
  const { subjectId } = getQuery();
  invokeAppByYYB({
    targetPath: `subject`,
    params: {
      tab: "",
      subjectId,
      // tab=auction 拍卖
      // tab=price 一口价
    },
  });
};

// const initJump = () => {
//   setTimeout(() => {
//     // TODO 如果是qq浏览器 --> 不prepareJump, 使用js对.mobLink::subject进行event注册, () => {弹出cover引导默认浏览器页}
//     const { subjectId } = getQuery();
//     prepareJump({
//       atrr: ".mobLink::subject",
//       targetPath: `/subject`,
//       params: {
//         tab: "",
//         subjectId,
//         // tab=auction 拍卖
//         // tab=price 一口价
//       },
//     });
//
//     prepareJump({
//       atrr: ".mobLink::subject::auction",
//       targetPath: `/subject`,
//       params: {
//         tab: "auction",
//         subjectId,
//       },
//     });
//
//     prepareJump({
//       atrr: ".mobLink::subject::price",
//       targetPath: `/subject`,
//       params: {
//         tab: "price",
//         subjectId,
//       },
//     });
//   }, 0);
// };

const onShowBidList = () => {
  const { subjectId } = getQuery();
  console.log("detailData", detailData);
  router.push(
    `/subject-bid-list?subjectId=${subjectId}&title=${detailData.value.name}`
  );
};

const onClickMenuItem = (item) => {
  currentTab.value = item.key;
};

const initData = async () => {
  const { subjectId } = getQuery();
  // const { data } = await NftApi.getSubjectList({ subjectId, limit: 20, offset: 0 });
  const { data } = await NftApi.getSubjectInfo(subjectId);
  // detailData = reactive(data.token || {})
  detailData.value = data.subject;

  // initJump();
  document.title = `${detailData.value.name}`;

  // console.log("detailData===", detailData);
};

initData();
</script>

<style lang="scss" scoped>
img {
  vertical-align: bottom;
}
.page {
  position: relative;
  min-height: 100vh;
  padding-bottom: 72rpx;
  background-color: #f8f8f8;
  .top-cover {
    width: 750rpx;
    height: 750rpx;
    object-fit: cover;
    vertical-align: bottom;
  }
  .info {
    padding-top: 48rpx;
    padding-left: 30rpx;
    background: linear-gradient(
      179.51deg,
      rgba(126, 103, 192, 0.1) 0.42%,
      rgba(248, 248, 248, 0.1) 99.58%
    );
    &-title {
      font-weight: bold;
      font-size: 64rpx;
      line-height: 80rpx;
      color: #000000;
    }
    &-desc {
      margin-top: 24rpx;
      font-weight: 400;
      font-size: 32rpx;
      line-height: 48rpx;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .list-btn {
    border: 2rpx solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
