<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <div v-if="isShowNavBar" id="subject-bid-list-nav">
    <van-nav-bar
      v-if="isShowNavigator"
      left-arrow
      @click-left="onClickLeft"
      :title="localTitle"
    />
    <van-tabs v-model:active="currentActiveTab" @click-tab="onClickTab">
      <van-tab
        :title="item.stageName"
        v-for="(item, index) in validTabas"
        :key="index"
      />
    </van-tabs>
  </div>
  <div>
    <div></div>

    <div class="container">
      <div
        class="container-item item"
        v-for="(item, index) in listInfo"
        :key="index"
        :id="item.tagName"
      >
        <div class="item-content" v-if="item.listData?.length">
          <div class="item-content-title">
            {{ item.stageName }}
          </div>
          <div class="list">
            <div
              class="list-item"
              v-for="(single, singleIndex) in item.listData"
              :key="singleIndex"
              @click="() => handleClickItem(single)"
            >
              <div class="list-item-left">
                <van-image
                  fit="cover"
                  class="list-item-left-avatar"
                  :src="
                    single.token?.covers?.[0]?.url ||
                    single.token?.watermaskImages?.[0]?.url +
                      '!mfit_w480_h480_jpg'
                  "
                  alt=""
                />
                <div class="info">
                  <div class="info-title otext">
                    {{ single.token.name }}
                  </div>
                  <div class="info-owner" v-if="item.tagName === 'bidding'">
                    <div class="info-owner-preifx">最高价</div>
                    <van-image
                      fit="cover"
                      class="info-owner-avatar"
                      :src="single.bidder?.avatarUrl + '!mfit_w480_h480_jpg'"
                      alt=""
                    />
                    <div class="info-owner-name">
                      {{ single?.bidder?.displayName }}
                    </div>
                  </div>
                  <div class="info-owner" v-else-if="item.tagName === 'traded'">
                    <div class="info-owner-preifx">拥有者</div>
                    <van-image
                      fit="cover"
                      class="info-owner-avatar"
                      :src="
                        single.token?.owner?.avatarUrl + '!mfit_w480_h480_jpg'
                      "
                      alt=""
                    />
                    <div class="info-owner-name">
                      {{ single?.token?.owner?.displayName }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item-right">
                <div class="price">
                  <div class="price-tips" v-if="single.tradeType === 'AUCTION'">
                    {{ item.priceTips }}
                  </div>
                  <div class="price-value">¥{{ single.price }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  NavBar as VanNavBar,
  Image as VanImage,
  Tab as VanTab,
  Tabs as VanTabs,
} from "vant";
import { throttle } from "lodash";
import { getQuery } from "@/utils/url";
import NftApi from "@/apis";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jb from "@frontend/effuse";

const route = useRoute();
const router = useRouter();

const subjectId = ref(route?.query?.subjectId);
const listInfo = ref([]);
const currentActiveTab = ref(0);
const isShowNavBar = ref(true);
const isShowNavigator = ref(true);
const localTitle = ref("");

// const handleChange = (newParams) => {
//   alert(`newParams:${newParams}`);
//   console.log(222);
// };

// watch(
//   () => route?.query?.subjectId,
//   (newParams, oldParams) => {
//     // 在这里处理参数变化时的逻辑
//     subjectId.value = newParams;
//     if (newParams !== oldParams) {
//       handleChange(newParams);
//     }
//   }
// );

const onClickLeft = () => {
  router.back();
};

const validTabas = computed(() => {
  const tempList = listInfo.value.filter((item) => item.listData.length);

  console.log("list===", tempList);
  return tempList;
});

const initData = async () => {
  const { subjectId, hideNavBar, hideNavigator, title } = getQuery();
  localTitle.value = title ? decodeURIComponent(title) : "";
  if (hideNavBar === "YES") {
    isShowNavBar.value = false;
  }
  if (hideNavigator === "YES") {
    isShowNavigator.value = false;
  }
  // const { data } = await NftApi.getSubjectList({ subjectId, limit: 20, offset: 0 });
  const { data } = await NftApi.getSubjectMgList({
    subjectId,
  });
  // detailData = reactive(data.token || {})
  listInfo.value = [
    {
      stageName: "竞价中",
      priceTips: "最高价",
      tagName: "bidding",
      listData: data?.biddingList || [],
    },
    {
      stageName: "待领养",
      priceTips: "起拍价",
      tagName: "pending",
      listData: data?.pendingList || [],
      // listData: data?.biddingList || [],
    },
    {
      stageName: "已成交",
      priceTips: "",
      tagName: "traded",
      listData: data?.tradedList || [],
    },
  ];
};

const handleClickItem = (itemData) => {
  // jb.navigateTo({ url: `congress://token/${itemData.token.id}` });

  if (window.isInFlutter) {
    jb.navigateTo({ url: `congress://token/${itemData.token.id}` });
  } else {
    router.push(`/token-detail?tokenId=${itemData.token.id}`);
  }
};

const scrollToTop = (elementId) => {
  const element = document.getElementById(elementId);
  const elementTop = element.getBoundingClientRect().top;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const currentPosition = scrollTop + elementTop;

  window.scrollTo({
    top: currentPosition,
    behavior: "smooth",
  });
};

const onClickTab = (data) => {
  // console.log(data);
  scrollToTop(validTabas.value[data.name].tagName);
};

const listenScroll = () => {
  if (!isShowNavBar.value) return;
  const navBar = document.getElementById("subject-bid-list-nav");
  const scrollTop = window.pageYOffset;
  const navBarHeight = navBar.clientHeight;

  if (scrollTop > navBarHeight) {
    // navBar.style.background = 'red!important';
    navBar.classList.add("stickyBar");
  } else {
    navBar.classList.remove("stickyBar");
    // navBar.style.background = 'transparent';
  }
};

const throttledHandleScroll = throttle(listenScroll, 100);

window.addEventListener("scroll", throttledHandleScroll);

initData();

// setTimeout(() => {
//   router.push("/subject-bid-list?subjectId=123");
// }, 1000);
</script>

<style lang="scss">
img {
  vertical-align: bottom;
}

body {
  background-color: unset;
}
#app {
  background-color: unset;
}

.container {
  width: calc(100% - 40rpx);
  margin: 0 auto;
  //padding: 0 20rpx;
  //min-height: 100vh;
  background: #f8f8f8;
  overflow-y: scroll;
  .item {
    overflow-y: scroll;
    &-content {
      padding: 22rpx 16rpx;
      background: white;
      box-shadow: 0 8rpx 40rpx rgba(0, 0, 0, 0.06);
      margin-bottom: 24rpx;
      border-radius: 16rpx;
      overflow: hidden;
      &-title {
        font-weight: 500;
        font-size: 32rpx;
        line-height: 48rpx;
        color: #000000;
      }
      .list {
        display: flex;
        flex-direction: column;
        //background-color: skyblue;
        &-item {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 32rpx;
          &-left {
            display: flex;
            align-items: center;
            &-avatar {
              width: 88rpx;
              height: 88rpx;
              border-radius: 8rpx;
              overflow: hidden;
            }
            .info {
              margin-left: 18rpx;
              &-title {
                max-width: 446rpx;
                font-weight: 400;
                font-size: 28rpx;
                line-height: 44rpx;
                color: #000000;
              }
              &-owner {
                margin-top: 13rpx;
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 20rpx;
                line-height: 22rpx;
                color: #000000;
                //&-prefix {}
                &-avatar {
                  margin: 0 8rpx;
                  width: 32rpx;
                  height: 32rpx;
                  border-radius: 100%;
                  border: 1rpx solid rgba(0, 0, 0, 0.1);
                  overflow: hidden;
                }
                &-name {
                  font-weight: 600;
                  max-width: 300rpx;
                }
              }
            }
          }
          &-right {
            .price {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              &-tips {
                font-weight: 400;
                font-size: 24rpx;
                line-height: 36rpx;
                color: #7247dc;
              }
              &-value {
                font-weight: 900;
                font-size: 32rpx;
                line-height: 48rpx;
                font-family: "Roboto";
                color: #7247dc;
              }
            }
          }
        }
      }
      .list-item:nth-child(1) {
        margin-top: 40rpx;
      }
    }
  }
}
//:root:root {
#subject-bid-list-nav {
  --van-nav-bar-background: transparent;
  --van-nav-bar-icon-color: #000000;
  --van-nav-bar-arrow-size: 40rpx;
  --van-nav-bar-title-font-size: 36rpx;

  --van-tabs-bottom-bar-width: 32rpx;
  --van-tabs-bottom-bar-height: 8rpx;
  --van-tabs-bottom-bar-color: #7247dc;

  margin-bottom: 12rpx;
  padding: 0 20rpx;

  .van-nav-bar__left {
    padding: unset;
  }
  //.van-tabs {
  //  position: sticky;
  //  top: 0;left: 0;
  //}
  .van-tabs__nav {
    background: transparent;
  }
  .van-tabs__line {
    bottom: 40rpx;
    border-radius: 1rpx;
  }
  .van-tab {
    flex: unset;
    margin-right: 34rpx;
    font-size: 28rpx;
    line-height: 44rpx;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.64);
  }
  .van-tab--active {
    font-weight: 500;
    color: #000000;
  }
}

.stickyBar {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #f8f8f8;
  border-radius: 0 0 8rpx 8rpx;
}
</style>
